import ApiService from "@/common/api/api.service";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

import Store from "../../../store/Store";

const ProdutosService = {
  listar : function(filtro, paginacao = 0, porPagina = 0, ativo, estabelecimento = null){
    let link = `produto?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    let estabecimentoSelecionado = Store.getters['estabelecimentoSelecionado'];
    link = link.concat(`${estabecimentoSelecionado || estabelecimento ? `&estabelecimentoId=${estabelecimento ? estabelecimento : estabecimentoSelecionado}` : ''}`);
    link = link.concat(`${ativo ? `&Ativo=true` : ''}`);
    link = link.concat(`${filtro ? `&TermoPesquisa=${filtro}` : ''}`);
    return ApiService.get(link);
  },
  listarAtivos : function(estabelecimento = null){
    return this.listar(null,0,0,true, estabelecimento);
  },
  salvar : function(form){
    let payloadForm = cloneDeep(form);
    payloadForm.valor = helpers.removerValor(payloadForm.valor);
    
    if(payloadForm.id){
      return ApiService.put("produto", payloadForm);
    }
    return ApiService.post("produto", payloadForm);
  },
  ativarInativar : function(form, ativarInativar){
    if(ativarInativar){
      return ApiService.put(`produto/ativar`, form);
    }
    return ApiService.put(`produto/inativar`, form);
  },
  buscar : function(id){
    return ApiService.get(`produto/${id}`);
  },
  dropdown : function(estabelecimentoId){
    if(!estabelecimentoId)
      return;
    let link = `produto/dropdown`;
    link = link.concat(`?EstabelecimentoId=${estabelecimentoId}`);
    return ApiService.get(link);
  },
};

export default ProdutosService;
