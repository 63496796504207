<template>
  <div>
    <b-card>
      <div>
        <h4 class="card-title">{{ tabela.quantidadeItens }} registros</h4>
      </div>
      <div class="filtro-valorem d-flex align-items-center justify-content-between mb-2">
        <input-text
          id="input-filtro"
          no-label
          :placeholder="$t('PRODUTOS.FILTRO')"
          v-model="filtro"
          @blur="filtrar"
          class="w-50"
        />
        <b-button id="btn-novo" @click="novo" v-if="permissao.criar" variant="primary">
          {{ $t("PRODUTOS.NOVO") }}
        </b-button>
      </div>

      <div class="d-flex align-items-center mb-2">
        <b-button
          id="btn-editar"
          @click="editar(null)"
          v-if="dadosSelecionados.length == 1"
          class="mr-2"
          variant="outline-primary"
        >
          {{ $t("GERAL.EDITAR") }}
        </b-button>
        <b-button
          id="btn-alterar-situacao"
          @click="ativarInativar(botaoAtivarInativar)"
          v-if="dadosSelecionados.length > 0 && permissao.editar"
          variant="outline-primary"
        >
          {{ `${!botaoAtivarInativar ? $t("GERAL.ATIVAR") : $t("GERAL.INATIVAR")}` }}
        </b-button>
      </div>

      <valorem-tabela
        sem-selecionar-todos
        :metodo-validacao-selecionar="metodoValidacaoSelecionar"
        v-model="tabela.dados"
        :colunas="tabela.colunas"
        :quantidade-itens="tabela.quantidadeItens"
        ref="tabela"
        backend-paginado
        @paginar="listar"
      >
        <template #cell(ativo)="dados">
          <span :class="`text-${retornarVariante(dados.value).variante} text-capitalize`">
            {{ retornarVariante(dados.value).text }}
          </span>
        </template>
      </valorem-tabela>
    </b-card>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import ProdutosService from "@/common/services/produtos/produtos.service";
import { InputText } from "../../components/inputs";
import helpers from "../../common/utils/helpers";
export default {
  components: {
    InputText,
  },
  data: function () {
    return {
      filtro: "",
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          { key: "codigo", label: this.$t("PRODUTOS.TABELA.CODIGO"), sortable: true },
          { key: "nome", label: this.$t("PRODUTOS.TABELA.PRODUTO"), sortable: true },
          {
            key: "valor",
            label: this.$t("PRODUTOS.TABELA.VALOR"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
            sortable: true,
          },
          { key: "ativo", label: this.$t("PRODUTOS.TABELA.SITUACAO"), sortable: true },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
      },
      botaoAtivarInativar: null,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("PRODUTOS.TITULO_LISTA") },
    ]);
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.$refs.tabela.limpar();
      this.listar();
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      ProdutosService.listar(this.filtro, paginaAtual, porPagina)
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.paginaAtual = paginaAtual;
          this.porPagina = porPagina;
          this.tabela.quantidadeItens = res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    novo: function () {
      this.$router.push({ name: "produtos-novo" });
    },
    editar: function (id) {
      if (!id) {
        this.$router.push({
          name: "produtos-editar",
          params: { id: this.dadosSelecionados[0].id },
        });
      } else {
        this.$router.push({ name: "produtos-editar", params: { id: id } });
      }
    },
    ativarInativar: function (ativarInativar) {
      this.confirmar(
        this.$t(`PRODUTOS.TITULO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`PRODUTOS.CONFIRMACAO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`GERAL.BOTAO_${!ativarInativar ? "ATIVAR" : "INATIVAR"}`),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        ProdutosService.ativarInativar(
          { produtosId: this.dadosSelecionados.map((el) => el.id) },
          !ativarInativar
        )
          .then(() => {
            this.listar(this.paginaAtual, this.porPagina);
            this.toastSucesso(
              this.$t(`PRODUTOS.${!ativarInativar ? "ATIVAR" : "INATIVAR"}_SUCESSO`)
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    retornarVariante: function (situacao) {
      return helpers.Situacao.find((el) => el.value == situacao);
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoAtivarInativar == null) return true;
      if (item.ativo != this.botaoAtivarInativar) return false;
      return true;
    },
  },
  computed: {
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    estabelecimentoSelecionado: function () {
      return this.$store.getters["estabelecimentoSelecionado"];
    },
    permissao: function () {
      return helpers.validarAcesso("Produto");
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
    estabelecimentoSelecionado: function () {
      this.listar();
    },
  },
};
</script>
<style lang=""></style>
